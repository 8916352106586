import React, { Fragment, useEffect } from 'react';
import { storage } from 'services';
import { getSafeHtml } from 'services/Helpers';
import root from 'window-or-global';
import { HorizontalAlignment } from '../../enums/HorizontalAlignment';
import { useLoadVidyardVideo, watchVidyardVideo } from '../../services/VidyardService';
import { ResponsiveImage } from '../ResponsiveImage';
import { HeroHeight } from './HeroHeight.enum';
import { HeroProps } from './Hero.props';
import { ImageCaptions } from './ImageCaptions';
import {
	Video,
	Container,
	Wrapper,
	HeroContent,
	HeroWrapper,
	ImgLogo,
	HeroTitle,
	HeroSubtitle,
	HeroActionLink,
	LinkVideo,
	HeroFooter,
	HeroIconButton,
	LinkTermsAndConditions,
} from './Hero.styles';

export const Hero = ({
	actionLink,
	backgroundVideo,
	backgroundHeroImage,
	backgroundHeroImages,
	enableScrollDown,
	heroHeight,
	horizontalAlignment,
	iconMaxWidth,
	logo,
	subtitle,
	title,
	vidyardEmbedCode,
	termsAndConditionsLink,
}: HeroProps) => {
	const [vidyardScriptLoaded] = useLoadVidyardVideo(vidyardEmbedCode);
	const hasBackgroundHeroImages = Array.isArray(backgroundHeroImages) && backgroundHeroImages.length;
	const [image, setImage] = React.useState(backgroundHeroImage);
	const iconStyle = {
		maxWidth: iconMaxWidth && /^\d+$/.test(iconMaxWidth) ? `${iconMaxWidth}px` : '',
	};
	const createMarkupFor = htmlString => {
		return getSafeHtml(htmlString);
	};
	const createMarkupForTitle = htmlString => {
		const heroHtmlString = htmlString
			.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '')
			.replace(/<br ?\/?>/g, '<div class="hero__conditional-title-break"></div>');
		return createMarkupFor(heroHtmlString);
	};

	const getAlignment = (alignment: HorizontalAlignment) => {
		switch (alignment) {
			case HorizontalAlignment.Start:
				return 'start-xs';
			case HorizontalAlignment.End:
				return 'end-xs';
			default:
				return 'center-xs';
		}
	};
	const heroRef = React.useRef(null);
	const backgroundVideoElement = React.useRef(null);

	const scrollToBottom = () => {
		root.scroll({
			top: heroRef.current.offsetHeight,
			behavior: 'smooth',
		});
	};

	const showVideo = backgroundVideo.sources !== undefined && backgroundVideo.sources.length > 0;

	useEffect(() => {
		if (hasBackgroundHeroImages) {
			let heroImage = backgroundHeroImages[0];
			const lastHeroImage = storage.get('hero-image-path');

			if (lastHeroImage) {
				const lastIndex = backgroundHeroImages.findIndex(img => img.fullPath === lastHeroImage);
				const nextIndex = backgroundHeroImages.length - 1 === lastIndex ? 0 : lastIndex + 1;
				const nextHeroImage = backgroundHeroImages[nextIndex];
				setImage(nextHeroImage);
				heroImage = nextHeroImage;
			} else {
				setImage(heroImage);
			}

			storage.set('hero-image-path', heroImage.fullPath);
		} else if (backgroundHeroImage) {
			setImage(backgroundHeroImage);
		}
	}, []);

	return (
		<Fragment>
			<Container ref={heroRef} automaticHeight={heroHeight === HeroHeight.AutomaticHeight}>
				<Wrapper>
					{image && <ResponsiveImage url={`${image.fullPath}&compression=80`} />}
					{showVideo && (
						<Video
							ref={backgroundVideoElement}
							preload='auto'
							controls={backgroundVideo.controls}
							loop={backgroundVideo.loop}
							autoPlay={backgroundVideo.autoplay}
							playsInline
							muted
						>
							{backgroundVideo.sources.map(source => (
								<source src={source.uri} type={`video/${source.encoding}`} key={source.uri} />
							))}
						</Video>
					)}

					<HeroContent>
						<HeroWrapper
							className={`row ${getAlignment(horizontalAlignment)}`}
							horizontalAlignment={horizontalAlignment}
						>
							<div className='col-xs-12 col-sm-10 col-md-8'>
								{logo && logo.hasImage && <ImgLogo alt='hero' src={logo.fullPath} style={iconStyle} />}

								{title && (
									<HeroTitle
										dangerouslySetInnerHTML={createMarkupForTitle(title)}
										hidden={logo && logo.hasImage}
									/>
								)}

								{subtitle && (
									<HeroSubtitle as='div' dangerouslySetInnerHTML={createMarkupFor(subtitle)} />
								)}

								<HeroActionLink {...actionLink} />

								{vidyardEmbedCode && vidyardEmbedCode.length > 0 && vidyardScriptLoaded && (
									<LinkVideo
										color='white'
										onClick={() => watchVidyardVideo(vidyardEmbedCode)}
										className='hero__video-play'
									>
										Watch Video
									</LinkVideo>
								)}
							</div>
						</HeroWrapper>
					</HeroContent>

					{enableScrollDown && (
						<HeroFooter className='center-xs'>
							<div className='col-xs-12'>
								<HeroIconButton
									buttonType='primaryInvert'
									iconName='arrow'
									iconSize='md'
									buttonSize='large'
									enableFloat
									onClick={scrollToBottom}
								/>
							</div>
						</HeroFooter>
					)}

					{termsAndConditionsLink && termsAndConditionsLink.canDisplayAsTextLink && (
						<LinkTermsAndConditions
							color='white'
							href={termsAndConditionsLink.url}
							target={termsAndConditionsLink.newWindow ? '_blank' : '_self'}
						>
							{termsAndConditionsLink.linkTitle}
						</LinkTermsAndConditions>
					)}
					{image && (image.url || image.caption) && <ImageCaptions {...image} />}
				</Wrapper>
			</Container>
		</Fragment>
	);
};
